body {
    background-color: rgb(131, 183, 246);
  }
input {
    text-align: center;
    font-size: 15px;
    font-weight: 900;
    color: blue;
}
select {
    text-align: center;
    font-size: 15px;
    font-weight: 900;
    color: blue; 
}
table, tr, th, td {
    border: 1px black solid;
    border-collapse: collapse;
    padding: 2px;
    text-align: center;
    margin: 12px;
    width: auto;
    height: auto;
}
h2, h3 {
    margin: 2px;
    text-align: center;
}
h4 {
    margin: 2px;
    /* text-align: left; */
}
tfoot {
    margin: 2px;
    text-align: center;  
}
h1 {
    margin: 5px;
    text-align: center;
    font-size: 20px;
    font-weight: 900;
}
.variable {
    background-color: rgb(115, 251, 133);
}
.attention {
    background-color: yellow;
}
.calculated {
    background-color: rgb(247, 153, 153);
}
.buttonNext {
    width: 100px;
    height: 40px;
    background-color: white;
    margin-left: 2px;
    margin-right: 2px;
    font-size: 20px;
    font-weight: 900;
}
.buttonSave {
    width: 250px;
    height: 50px;
    background-color: greenyellow;
    font-size: 15px;
    font-weight: 900;
}
.buttonCalc {
    width: 300px;
    height: 30px;
    background-color: rgb(252, 232, 228);
    font-size: 15px;
    font-weight: 900;
}
.buttonNew {
    width: 200px;
    height: 30px;
    background-color: greenyellow;
    font-size: 15px;
    font-weight: 900;
    margin-left: 10px;
}
.notes {
    width: 200px;
    height: 30px;
    font-size: 15px;
    font-weight: 900;
    margin-left: 10px;
}
.dialog {
    z-index: 999999;
    position: static;
    width: 320px;
    height: auto;
    background-color: #fff;
}
.clicDialog {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 30px;
    font-size: 15px;
    font-weight: 900;
}
[contenteditable]:focus {
    outline: 0px solid transparent;
}
.delite {
    background-color: #f08d8d;
    border: 3px solid red;
    width: 200px;
    height: 40px;
    font-size: 15px;
    font-weight: 900;
    margin-left: 10px;
}
.load {
    position: absolute;
    margin: 0;
    left: 6%;
    top: 50%;
    /* margin-left: -50px;
    margin-top: -30px; */
}
.deleteOk {
    position: absolute;
    margin: 0;
    left: 6%;
    top: 50%;
}
.deletess {
    width: 100px;
    height: 40px;
    font-size: 15px;
    font-weight: 900;
    margin-left: 30px;
    margin-top: 20px;
}
.divLogin {
    text-align: center;
    padding: 3px;
    
}
div {
    /* width: 410px;
    position: relative;
    left: 50%;
    margin-left: -205px;
    margin-right: -205px; */
}
